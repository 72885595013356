import React, { useContext } from 'react'
import '../../assets/css/PageNavigate.css'

function PageNavigate({ postPerPage, totalPosts, paginate }) {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postPerPage); i++) {
    pageNumbers.push(i)
  }

//   const pageActive=()=>{
//     const header = document.getElementById('list')
//     const btn = header.getElementsByClassName('pages') 
//     for(let i=0 ; i < btn.length ; i++){
//       btn[i].addEventListener('click',function(){
//         const current = document.getElementsByClassName('pages-item')
//         current[0].className = current[0].className.replace("pages-item", "pages");
//         this.className +='pages-item'
//       })
//     }
//  }

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center">
        {pageNumbers.map((number,index) => (
          <li key={number} className='page-item' id='list'>
            <button onClick={() => paginate(number)} className="page-link pages">{number}</button>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default PageNavigate