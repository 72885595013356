import React from "react";
import Footer from "../userLayout/Footer";
import Header from "./header/Header";


function Layout (props) {
    return (
      <>
        <Header />
        <main>{props.children}</main>
        <Footer />
      </>
    );
  }

export default Layout;
