import React from "react";
// import "../../container/carDetails/CarDetails.css"


const Input = ({placeholder, type, name, onChange,accept,style, className, maxLength}) => {
    return (
            <div className="form-group">
                <input
                    type={type}
                    name={name}
                    style={style}
                    className={className}
                    placeholder={placeholder}
                    onChange={onChange}
                    accept={accept}
                    maxLength={maxLength}
                />
            </div>
    );
};
export default Input;
