import React, { createContext, useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { GlobalVariable } from "../../baseUrl/baseUrl";

export const CarDetailsContext = createContext({});

export const CarDetailsContextProvider = ({ children }) => {
  const [showModal, setshowModal] = useState(false);
  const [imgArr, setImgArr] = useState([]);
  const [carModel, setCarModel] = useState();
  const [carOdoMeter, setCarOdoMeter] = useState();
  const [fuelType, setFuelType] = useState("");
  const [carPrice, setCarPrice] = useState()
  const [carDescription, setCarDescription] = useState("");
  const [dealerName, setDealerName] = useState("");
  const [pdfFile, setPdfFile] = useState(null);
  const [dealerId, setDealerId] = useState();
  const [dealerEmail, setDealerEmail] = useState("")
  const [indexVal, setIndexVal] = useState()
  const [fuelIndex, setFuelIndex] = useState()
  const [isPublished, setIsPublished] = useState(false);

  const [invalidfuel, setInvalidFuel] = useState(false)
  const [invalidDealername, setInvalidDealerName] = useState(false)

  const [invalidModel, setInvalidModel] = useState(false)
  const [invalidOdoMeter, setInvalidOdometer] = useState(false)
  const [invalidCarPrice, setInvalidCarPrice] = useState(false)
  const [invalidPublish, setInvalidPublish] = useState(false)
  const [invalidimg, setInvalidimg] = useState(false)

 
  const updateInvalidModel = (model) => {
    setInvalidModel(model)
  }
  const updateinvalidOdoMeter = (status) => {
    setInvalidOdometer(status)
  }
  const updateIndexVal = (index) => {
    setIndexVal(index)
  }
  const updateInvalidFuel = (fuel) => {
    setInvalidFuel(fuel)
  }

  const updateInvalidimg = (param) => {
    setInvalidimg(param)
  }

  const updateInvalidPublish = (param) => {
    setInvalidPublish(param)
  }

  const updateInvalidCarPrice = (param) => {
    setInvalidCarPrice(param)
  }

  const UpdateCarPrice = (param) => {
    setCarPrice(param)
  }

  const updateInvalidDealerName = (name) => {
    setInvalidDealerName(name)
  }

  const updatefuelIndex = (index) => {
    setFuelIndex(index)
  }
  const updateModal = (modal) => {
    setshowModal(modal);
  };
  const updatePdfFile = (pdf) => {
    setPdfFile(pdf);
  };
  const updateFuelType = (fuel) => {
    setFuelType(fuel);
  };

  const updateCarModel = (model) => {
    setCarModel(model);
  };
  const updateCarOdoMeter = (odoMeter) => {
    setCarOdoMeter(odoMeter);
  };
  const updateCarDescription = (description) => {
    setCarDescription(description);
  };
  const setToggle = (toggle) => {
    if (showModal === true) {
      setshowModal(false);
    } else {
      setshowModal(true);
    }
  };

  const updateDealerId = (id) => {
    setDealerId(id)
  }
  // console.log("dealerId", dealerId)
  // console.log("dealer email", dealerEmail)
  const updateDealerEmail = (email) => {
    setDealerEmail(email)
  }
  const updateImgArr = (arr) => {
    setImgArr(arr);
  };

  const updateDealerName = (name) => {
    setDealerName(name);
  };

  let inspectionId = uuidv4();
  let carId = dealerId + "_" + Date.now()


  const saveDetails = (show) => {
    if(carModel && carOdoMeter && carId && carDescription && carPrice &&
      fuelType && dealerName &&imgArr.length >= 1 && pdfFile !== null ){
        const formdata = new FormData();

    formdata.append("dealername", dealerName)
    formdata.append("carmodel", carModel);
    formdata.append("fuelType", fuelType);
    formdata.append("odometer", carOdoMeter);
    formdata.append("inspectionId", inspectionId)
    formdata.append("description", carDescription);
    formdata.append("dealerId", dealerId)
    formdata.append("dealeremail", dealerEmail)
    formdata.append("carId", carId)
    formdata.append("carPrice", carPrice)
    formdata.append("inspectorId", localStorage.getItem("inspectorId"))
    formdata.append("pdf", pdfFile);
    for (let i = 0; i < imgArr.length; i++) {
      formdata.append("img[]", imgArr[i]);
      formdata.getAll("img[]");
    }

    axios.post(`${GlobalVariable.BACKEND_API_URL}/save-details`, formdata, {
      headers: {
        "content-type": "multipart/form-data",
        "token": localStorage.getItem("token")
      }
    })
      .then((res) => {
        setIsPublished(true);
        setTimeout(show, 5000)
      }).then(()=>{
        window.location.reload();
      })
      .catch((err) => console.log(err))
      }
else{
  setInvalidPublish(true)
  console.log("cannot published");
}
    // console.log(dealerName, carModel, fuelType, carOdoMeter, carDescription)
    
  }

  const FormValidation = (e) => {
    console.log("form validation")
    if (indexVal === undefined) {
      updateInvalidDealerName(true)
    }
    if (fuelIndex === undefined) {
      updateInvalidFuel(true)
    }
    if (carModel === undefined) {
      updateInvalidModel(true)
    }
    if (carOdoMeter === undefined || !/^\d{6}$/.test(carOdoMeter)) {
      updateinvalidOdoMeter(true)
    }
    if (carPrice === undefined) {
      updateInvalidCarPrice(true)
    }
    setInvalidimg(true)
    if (indexVal === undefined || fuelIndex === undefined
      || carModel === undefined || carOdoMeter === undefined) {
      return false
    }

    return true
  }

  const dealer = []//["Choose dealer"]

  return (
    <CarDetailsContext.Provider
      value={{
        showModal,
        updateModal,
        setshowModal,
        setToggle,
        imgArr,
        updateImgArr,
        carModel,
        carOdoMeter,
        carDescription,
        updateCarModel,
        updateCarOdoMeter,
        updateCarDescription,
        fuelType,
        updateFuelType,
        dealerName,
        updateDealerName,
        pdfFile,
        updatePdfFile,
        saveDetails,
        dealer,
        updateDealerEmail,
        updateDealerId,
        dealerId,
        dealerEmail,
        updateIndexVal,
        updateInvalidFuel
        , updateInvalidDealerName,
        updatefuelIndex,
        indexVal,
        invalidfuel,
        invalidDealername,
        fuelIndex,
        invalidModel,
        invalidOdoMeter,
        updateInvalidModel,
        updateinvalidOdoMeter,
        FormValidation,
        carPrice, UpdateCarPrice,
        invalidCarPrice, updateInvalidCarPrice,
        invalidPublish, updateInvalidPublish,
        invalidimg, updateInvalidimg,
        isPublished
      }}
    >
      {children}
    </CarDetailsContext.Provider>
  );
};
