import emailjs from "@emailjs/browser";

const sendEmail = (params, fun) => {
  console.log('params', params);
  emailjs
    .send(
      "service_hojnwnm",
      "template_u5kiyia",
      params,
      "6xVZEwpEj6ej493V0"
    )
    .then(
      (result) => {
        console.log(result.text);
        console.log("message sent");
        if(fun){
          fun()
        }
      },
      (error) => {
        console.log(error.text);
      }
    );
};

export default sendEmail