import React,{useState} from "react";
import axios from 'axios'
import { GlobalVariable } from "../../baseUrl/baseUrl";

function getRand(){
    return new Date().getTime().toString() + Math.floor(Math.random()*1000000);
}
let uniqueId = getRand();
export const Register= ()=>{
    //uuidv4();
    console.log(uniqueId)
 
const [register,setRegister] = useState({
    username:"",
    password:"",
    inspectorId:0,
    email:"",
    phonenumber:0,
})

const RegBtn = (e)=>{
    e.preventDefault();
    const data ={...register,inspectorId:uniqueId}
    console.log(data)
  console.log(register);
  axios.post(`${GlobalVariable.BACKEND_API_URL}/register`,data).then((res)=> console.log(res))
  .catch((err)=> console.log(err))
}
    return(
        <form>
        <input type="text" placeholder ="username" value={register.username} onChange={(e)=>setRegister({...register,username:e.target.value})}/><br/><br/>
        <input type="password" placeholder ="password"  value={register.password} onChange={(e)=>setRegister({...register,password:e.target.value})}/><br/><br/>
        <input type="email" placeholder ="email"  value={register.email} onChange={(e)=>setRegister({...register,email:e.target.value})}/><br/><br/>
        <input type="number" placeholder ="phonenumber"  value={register.phonenumber} onChange={(e)=>setRegister({...register,phonenumber:e.target.value})}/><br/><br/>
        <button onClick={RegBtn}>Register</button>
        </form>
    )
} 