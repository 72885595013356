import React, { createContext, useState } from "react";
import DealersDetails from "../../../uitls/routers/dealersDetails";
import sendEmail from "../../email/email";

export const CarInfoContext = createContext();

export const CarInfoContextProvider = ({ children }) => {
  const [userFirstName, setUserFirstName] = useState();
  const [userLastName, setUserLastName] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [userEmail, setUserEmail] = useState();
  const [selectedCarDetail, setSelectedCarDetail] = useState();
  const [errMsgFirstName, setErrMsgFirstName] = useState(false)
  const [errMsgLastName, setErrMsgLastName] = useState(false)
  const [errMsgEmail, setErrMsgEmail] = useState(false)
  const [errMsgMobileNo, setErrMsgMobileNo] = useState(false)
  const [confirmMsg, setConfirmMsg] = useState(false)
  const [publishErr, setPublishErr] = useState(false);

  const {enquiryDetails} = DealersDetails();

  const updateFirstName = async (firstName) => {
    setUserFirstName(firstName)
  }

  const updateLastName = async (lastName) => {
    setUserLastName(lastName)
  }

  const updatemobileNumber = async (mobileno) => {
    setMobileNumber(mobileno)
  }

  const updateEamil = async (email) => {
    setUserEmail(email)
  }

  const updateCarDetails = async (details) => {
    setSelectedCarDetail(details)
  }

  const updateErrMsgFirstName = (param) => {
    setErrMsgFirstName(param)
  }

  const updateErrMsgLastName = (param) => {
    setErrMsgLastName(param)
  }

  const updateErrMsgEmail = (param) => {
    setErrMsgEmail(param)
  }

  const updateErrMsgMobile = (param) => {
    setErrMsgMobileNo(param)
  }

  const updateConfarmationMsg = (param) => {
    setConfirmMsg(param)
  }

  const enquireValidation = (params) => {
    if (userFirstName === undefined) {setErrMsgFirstName(true)}
    if (userLastName === undefined) {setErrMsgLastName(true)}
    if (userEmail === undefined) {setErrMsgEmail(true)}
    if (mobileNumber === undefined){setErrMsgMobileNo(true)}
  }
  
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(userEmail);
  }

  const publish = ( param, email) =>{
    if(userFirstName !== undefined && userLastName !== undefined &&
      userEmail !== undefined && mobileNumber !== undefined && isValidEmail()){
        enquiryDetails(param).then(email)
        setConfirmMsg(true)
        setPublishErr(false)
      }else{
        console.log("Not Validate");
        setPublishErr(true)
      }
  } 

  return (
    <CarInfoContext.Provider
      value={{
        userFirstName, updateFirstName,
        userLastName, updateLastName,
        mobileNumber, updatemobileNumber,
        userEmail, updateEamil,
        selectedCarDetail, updateCarDetails,
        errMsgFirstName, errMsgLastName,
        errMsgEmail, errMsgMobileNo,
        enquireValidation,updateErrMsgFirstName,
        updateErrMsgLastName,updateErrMsgEmail,
        updateErrMsgMobile,publish,
        confirmMsg,updateConfarmationMsg,
        publishErr
      }}
    >
      {children}
    </CarInfoContext.Provider>
  )

}