import React from "react";
import logo from "../../../assets/images/safe-buy-logo.png";
import { Link } from "react-router-dom";

function Header(){
    return(
        <header class="top_header header_bg">
        <div class="container pt-2">
            <div class="row">
                <div class="col-md-2">
                    <a class="navbar-brand" href="/admin"> <img src={logo} style={{ height:'52px', width:'160px' }} /></a>
                </div>
                <div class="col-md-10 header-link mt-3">
                    <ul class="d-block text-sm-end p-0">
                        <li class="nav-item ms-2 d-inline">

                            <span class="mb-3">
                                <svg className="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"></path>
                                    <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"></path>
                                </svg>
                                <Link to='/admin' roll='button' class="text-decoration-none" style={{marginLeft:'4px'}}>Sign Out</Link>
                            </span>

                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
    );
}
export default Header;