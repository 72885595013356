import React, { createContext, useState, useEffect } from "react";
import DealersDetails from "../../../uitls/routers/dealersDetails";
import axios from "axios";

export const DealersGalleryContext = createContext();

export const DealersGalleryProvider = ({ children }) => {
  const [allCollections, setAllCollection] = useState([])
  const [filteredGallary, setFilteredGallary] = useState([])
  const [filterByCars, setFilterByCars] = useState();
  const [filterByTypes, setFilterByTypes] = useState();
  const [filterByDealers, setFilterByDealers] = useState();
  const [carSort, SetCarSort] = useState();
  const [currentPage, setCurrentPage] = useState(1)
  const [postPerPage, setPostPerPage] = useState(6)
  const [testGallary, setTestGallary] = useState()
  const [carModel, setCarModel] = useState()
  const [carFuelType, setCarFuelType] = useState()
  const [carOdomerter, setCarOdomerter] = useState()
  const [carPrice, setCarPrice] = useState()
  const [dealerName, setDealerName] = useState()
  const [filterValue, setFilterValue] = useState()

  // console.log('filterByCars',filterByCars);
  // console.log('filterByTypes',filterByTypes);
  // console.log('filterByDealers',filterByDealers);
  // console.log('carSort',carSort);
  // console.log('filteredGallary',filteredGallary);
  // console.log('allCollections',allCollections.filter(item => item.carAskingPrice == 140));

  // console.log('filter', allCollections.filter((item) => item.dealerName.toLowerCase()));

  const updateDefaultCollections = async (params) => {
    setAllCollection(params);
  }

  const updateFilterByCar = async (params) => {
    setFilterByCars(params);
  };

  const updateFilterByType = async (params) => {
    setFilterByTypes(params);
  };

  const updateFilterByDealers = async (params) => {
    setFilterByDealers(params);
  };

  const updateCarsort = async (params) => {
    SetCarSort(params);
  };

  const updateCurrentPage = async (number) => {
    setCurrentPage(number)
  }

  const updateTestGallary = (params) => {
    setTestGallary(params)
  }

  const updateSetCarModel = (params) => {
    setCarModel(params)
  }

  const updateSetCarFuelType = (params) => {
    setCarFuelType(params)
  }

  const updateSetCarOdomerter = (params) => {
    setCarOdomerter(params)
  }

  const updateSetCarPrice = (params) => {
    setCarPrice(params)
  }

  const updateDealerName = (params) => {
    setDealerName(params)
  }

  const updateFilterValue=(params) => {
    setFilterValue(params)
  }

  const FilterBy = (params, filterType) => {
    switch (filterType) {
      case 'carName':
        return (
          allCollections.filter(item => item.carDetails.carModel.toLowerCase().includes(params.toLowerCase()))
            .map((data, indexVal) => {
              setFilteredGallary((currentVal) => {
                currentVal[indexVal] = {
                  ...currentVal[indexVal],
                  data,
                };
                return currentVal;
              })
            })
        )
      case 'type':
        return (
          allCollections.filter(item => item.carDetails.carFuelType.toLowerCase().includes(params.toLowerCase()))
            .map((data, indexVal) => {
              setFilteredGallary((currentVal) => {
                currentVal[indexVal] = {
                  ...currentVal[indexVal],
                  data,
                };
                return currentVal;
              })
            })
        )
      case 'dealer':
        return (
          allCollections.filter(item => item.dealerName.toLowerCase().includes(params.toLowerCase()))
            .map((data, indexVal) => {
              setFilteredGallary((currentVal) => {
                currentVal[indexVal] = {
                  ...currentVal[indexVal],
                  data,
                };
                return currentVal;
              })
            })
        )
      case 'sort':
        return (
          allCollections.filter(item => item.carAskingPrice == params)
            .map((data, indexVal) => {
              setFilteredGallary((currentVal) => {
                currentVal[indexVal] = {
                  ...currentVal[indexVal],
                  data,
                };
                return currentVal;
              })
            })
        )
    }
  }

  return (
    <DealersGalleryContext.Provider
      value={{
        allCollections, updateDefaultCollections,
        filterByCars, updateFilterByCar,
        filterByTypes, updateFilterByType,
        filterByDealers, updateFilterByDealers,
        carSort, updateCarsort,
        currentPage, updateCurrentPage,
        FilterBy, filteredGallary,
        postPerPage, testGallary, 
        updateTestGallary, 
        carModel, carFuelType, 
        carOdomerter, carPrice,
        updateSetCarModel,updateSetCarFuelType,
        updateSetCarOdomerter, updateSetCarPrice,
        dealerName, updateDealerName,
        filterValue, updateFilterValue
      }}
    >
      {children}
    </DealersGalleryContext.Provider>
  )

}