import React from 'react'
import Footer from './Footer'
import Header from './Header'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";

class Layout extends React.Component {
  render(){
    return (
      <>
      <Header />
      <main>{this.props.children}</main>
      <Footer />
      </>
    )
  }
}

export default Layout