import '../../App.css'
import React, { useContext, useState } from "react";
import Modal from '../components/modal/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
// import '../components/modal/Modal.css'
import { CarDetailsContext } from '../context/carDetailsContext/CarDetailsContext';


const Preview = ({ label }) => {

  const {carModel,carOdoMeter,
    fuelType,dealerName,FormValidation,imgArr,carPrice} = useContext(CarDetailsContext)

  const [open, setOpen] = useState()
  const handleClick = () => {
    if (open === true) {
      setOpen(false)
    } else {
      setOpen(true)
    }
    
  }
//  console.log("model:",carModel !== undefined && carOdoMeter !== undefined 
//  &&fuelType !== undefined && dealerName !== undefined && imgArr.length === 1);
//  console.log('model arr', imgArr);
  return (
    <div>
      <button
        type="button"
        className="btn btn-dark mt-4 main_btn border-0"
        onClick={()=>{ carModel !== undefined && !/^\d{6}$/.test(carOdoMeter) && carOdoMeter !== undefined 
          && fuelType !== undefined && dealerName !== undefined && carPrice !== undefined
          && imgArr.length >= 1
          ? handleClick() : FormValidation()}}
      >
        {label}
      </button>
      {open ?
      <div>
        <Modal show={() => handleClick()}/>
        </div>
        :
        ''
      }
    </div>
  );
};
export default Preview;