import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { CarInfoContext } from '../../common/context/CarInfoContext/CarInfoContext'
import PageNavigate from '../../common/components/PageNavigate'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import { GlobalVariable } from '../../common/baseUrl/baseUrl';

const FilteredGallery = ({ data, postPerPage, totalPosts, paginate }) => {
  const { updateCarDetails } = useContext(CarInfoContext);
  return (
    <>
      <div class="row page1">
        {data !== [] ?
          data.map((item, index) => (
            <div class="col-12 col-md-4 my-3">
              <div class="card">
                <img src={`${GlobalVariable.BACKEND_API_URL}/${item.data.carImage[0].ImgPath}/${item.data.carImage[0].ImgName}`} class="card-img-top" />
                <div class="card-body">
                  <div class="d-flex justify-content-between mb-1">
                    <div class="P-0">
                      <b>{item.data.carDetails.carModel}</b>
                    </div>
                    <div class="P-2 primary_font_color">
                      <b>{item.data.carAskingPrice}</b>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between mb-3">
                    <div class="P-0 font-12">
                      {item.data.carDetails.carOdoMeter} kilometer
                    </div>
                    <div class="P-0">
                      <b class="font-12">{item.data.carDetails.carFuelType}</b>
                    </div>
                  </div>
                  <div class="primary_color d-inline-block mt-4 ">
                    <Link to="/collectiondetails" role='button' onClick={() => updateCarDetails(item.data)} className='btn btn-dark main_btn border-0' >View More</Link>
                  </div>
                </div>
              </div>
            </div>
          )) : 'Loading...'}
      </div>
      <div class="col-12 mt-4">
        <PageNavigate totalPosts={totalPosts} postPerPage={postPerPage} paginate={paginate} />
      </div>
    </>
  )
}

export default FilteredGallery