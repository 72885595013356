import React from "react";
import Layout from "../../common/userLayout/Layout";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import '../../assets/css/Login.css'
import "../../assets/css/dealer.css"
import { Link } from "react-router-dom";

const ResetpwdSuccess = (props) => {
  return (
    <div className="text-center">
      <div className="admin-ui">
        <Layout>
          <div >
            <div className="pwd-container">
              <h1 style={{ color: "orange" }}>Reset password successfully</h1>
              <Link to="/admin"> <h6 className="text">Click here to login </h6></Link> 
            </div>
          </div>
        </Layout>
      </div>
    </div>
  );
};
export default ResetpwdSuccess;