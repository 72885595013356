import React, { useContext, useEffect, useRef} from "react";
// import "../../assets/css/CarDetails.css";
import CarImages from '../../common/components/carImages/CarImages';
import InspectionResult from '../../common/components/inspectionReport/InspectionReport';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import Input from '../../common/textInput/Input';
import Layout from "../../common/layout/Layout";
import { CarDetailsContext } from '../../common/context/carDetailsContext/CarDetailsContext'
import axios from 'axios'
import '../../assets/css/dealer.css'
import { GlobalVariable } from '../../common/baseUrl/baseUrl'
import { Navigate } from "react-router-dom";


function CarDetails() {

  const [dealerArr, setDealerArr] = useState(["Choose dealer"])
  const fuleTypes = ["Fuel Type", "Petrol", "Diesel", "Electric"]
  const { carOdoMeter, updateCarOdoMeter, updateFuelType, updateCarModel,
    fuelType, dealer, dealers, dealerName, updateDealerName,
    updateDealerId, updateDealerEmail, carModel, indexVal, invalidDealername,
    invalidfuel, fuelIndex, updateIndexVal, updateInvalidFuel, updateInvalidDealerName,
    updatefuelIndex, updateInvalidModel,
    updateinvalidOdoMeter, invalidModel, invalidOdoMeter, FormValidation,
    carPrice, UpdateCarPrice,
    invalidCarPrice, updateInvalidCarPrice
  } = useContext(CarDetailsContext)

  const navigate = useNavigate();
const logoutTimerIdRef = useRef(null);
const logoutUser =()=>{
    navigate("/admin")
}
useEffect(() => {
  const autoLogout = () => {
    if (document.visibilityState === 'hidden') {
      const timeOutId = window.setTimeout(logoutUser,/*12 * 60 *60 * 1000*/3000000);
      logoutTimerIdRef.current = timeOutId;
    } else {
      window.clearTimeout(logoutTimerIdRef.current);
    }
  }
  document.addEventListener('visibilitychange', autoLogout);
  return () => {
    document.removeEventListener('visibilitychange', autoLogout);
  };
}, []);

  const UserName = localStorage.getItem('userName')
  let data;
  useEffect(() => {
    axios.get(`${GlobalVariable.BACKEND_API_URL}/dealer-details`,
      { headers: { "token": localStorage.getItem("token") } })
      .then((res) => {
        // console.log(res)
        // console.log(res.data.results)
        let result = res.data.results;
        let newArr = []
        //  newArr.push("choose Dealer")
        newArr.push("Choose dealer")
        result.forEach((element, idx) => {

          newArr.push(element.username)
          // return element.username
        })
        // console.log("newArr", newArr)

        setDealerArr(newArr);
        if (indexVal !== undefined) {
          updateDealerId(result[indexVal-1].clientId)
          updateDealerEmail(result[indexVal-1].email)

        }

      })
      .catch((err) => {
        // console.log(err)
      })

  }, [indexVal])


  // console.log("dealer", dealerArr)
  // console.log("index", indexVal)


  // console.log(carModel, "car model")
  // console.log(fuelIndex, "fuel index")
  const user = localStorage.getItem("inspectorId")
  if(!user){
    return <Navigate to='/admin' replace />
  }
  return (
    <div className="text-center text-white">
      <div className="admin-ui">
        <Layout>
          <div className="mt-3">
            <div className="container body_height car_choosen">
              <div class="row">
                <div class="col forgot_password"><p class="welcome_heading text-start mt-4">Welcome <b>{UserName}</b></p></div>
              </div>
              <div className="row mt-4">
                {/* <form  enctype="multipart/form-data"> */}
                <div className="col-md-3 mb-3">
                  <select
                    className="form-select form_bg mb-4 p-2"
                    value={dealerName}
                    style={invalidDealername ? { borderStyle: "solid", borderColor: "red" } : {}}
                    onChange={(e) => {
                      updateDealerName(e.target.value)
                      updateIndexVal(e.target.selectedIndex)
                      updateInvalidDealerName(false)
                    }
                    }
                  >
                    {dealerArr ? (
                      dealerArr.map((element, idx) => (
                        <option key={idx}>{element}</option>
                        // {console.log("element",element)}
                      ))
                    ) : (
                      <option>Loading....</option>
                    )}
                  </select>

                  {/* {console.log(invalidModel)} */}
                  <Input
                    className='form-control mb-4 p-2'
                    placeholder="Car Make and Model"
                    type="text"
                    style={invalidModel ? { borderStyle: "solid", borderColor: "red" } : {}}
                    onChange={(e) => {
                      updateCarModel(e.target.value)
                      updateInvalidModel(false)
                    }}
                  />


                  <select
                    className="form-select form_bg mb-4 p-2"
                    value={fuelType}
                    style={invalidfuel ? { borderStyle: "solid", borderColor: "red" } : {}}
                    onChange={(e) => {
                      updateFuelType(e.target.value)
                      updatefuelIndex(e.target.selectedIndex)
                      updateInvalidFuel(false)

                    }
                    }
                  >
                    {fuleTypes ? (
                      fuleTypes.map((element, idx) => (
                        <option key={idx}>{element}</option>

                      ))
                    ) : (
                      <option>Loading....</option>
                    )}
                  </select>

                  <Input
                    className='form-control mb-4 p-2'
                    placeholder="Odometer :"
                    type="number"
                    style={invalidOdoMeter ? {borderStyle: "solid", borderColor: 'red' } : {}}
                    onChange={(e) => {
                      updateinvalidOdoMeter(false)
                      updateCarOdoMeter(e.target.value);
                    }}
                  />

                  <Input
                    className='form-control mb-4 p-2'
                    placeholder="Car Asking Price :"
                    type="number"
                    style={invalidCarPrice ? {borderStyle: "solid", borderColor: 'red' } : {}}
                    onChange={(e) => {
                      updateInvalidCarPrice(false)
                      UpdateCarPrice(e.target.value);
                    }}
                  />

                </div>

                <div className="col-md-5 mb-3">
                  <CarImages />
                </div>
                <div className="col-md-4 mb-3">
                  <InspectionResult />
                </div>

                {/* </form> */}
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  );
}
export default CarDetails;