import './App.css'; 
import CarDetails from '../../frontend/src/container/carDetails/CarDetails';
 import Login from '../../frontend/src/container/login/Login';
import {BrowserRouter,Routes,Route, Router, Navigate} from 'react-router-dom'
import {Register} from './common/components/register/regsiter'
// import {TestDropDown} from '../src/common/testDropdown'
import CarCollections from './container/carListingPage/CarCollections';
import CarInfo from './container/carCollectionDetails/CarInfo';
import {ForgotPwd} from "../src/container/login/forgotPwd"
import ResetPwd from '../src/container/login/ResetPwd'
import ForgotpwdSuccess from './container/login/Forgotpwd_success';
import ResetpwdSuccess from './container/login/Resetpwd_success';
import Cookielogin from './container/login/cookielogin';
import SignUpSuccess from './container/login/SignUp_success';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path='/admin' element={!navigator.cookieEnabled? <Cookielogin />:<Login />} />
        <Route path='/register' element={<Register/>} />
        <Route path='/details' element={<CarDetails/>} />
        <Route path='/forgotpwd' element={<ForgotPwd />} />
        <Route path='/sign-up' element={<SignUpSuccess/>}/>
        <Route path='/forgot-pwd-success' element={<ForgotpwdSuccess/> } />
        <Route exact path="/password-reset/:id/:token" element={<ResetPwd/>} />
        <Route path='/reset-pwd-success' element={<ResetpwdSuccess/> } />
          <Route path='/' element={<CarCollections />} />
          <Route path='/collectiondetails' element={<CarInfo /> } />
          <Route path='*' element={<Navigate to='/collections' replace/>} />
      </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;
