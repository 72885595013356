import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";

const Footer = () => {
  return (
    <div className='text-center'>
    <footer className="pt-3 font-12">
      <p className="white_color"> <a href="https://safebuy.com.au/read-the-terms-conditions-of-prepurchase-vehicle-services/" target="_blank" className="text-decoration-none">Terms conditions</a>   |   <a href="https://safebuy.com.au/read-the-polices-of-prepurchase-vehicle-services/" target="_blank" className="text-decoration-none">Privacy Policy </a>  |   <a href="#" className="text-decoration-none"> Copyright ©2022 SafeBuy. All Rights Reserved </a></p>
    </footer>
    </div>
  )
}

export default Footer