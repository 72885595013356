import axios from 'axios';
import { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/safe-buy-logo.png'
import Button from '../../common/button/Button'
import { CarDetailsContext } from '../../common/context/carDetailsContext/CarDetailsContext';
import '../../assets/css/Login.css';
import '../../assets/css/dealer.css'
import {GlobalVariable} from '../../common/baseUrl/baseUrl'


function Login() {
    return (
        <div className='text-center text-white'>
            <div className='admin-ui full_bg'>
                <div class="top_header">
                    <div class="container p-2">
                        <div class="row">
                            <div class="col-md-2">
                                <a class="navbar-brand" href="#"> <img src={logo} style={{ height:'52px', width:'160px' }} /></a>
                            </div>
                        </div>
                    </div>
                </div>
         
                        <div class="alert alert-success" role="alert">
                <strong>Allow  Cookies in the new Microsoft Edge!</strong> Open Microsoft Edge, select Menu (3 dots icon on top right corner of the browser) {">"} Settings {">"} Site permissions {">"} Cookies and site data

            // Turn on "Allow sites to save and read cookie data (recommended)" to unblock cookies

            // Turn on "Block third-party cookies" or add desired sites in "Block" section to block the cookies..
            </div>



                <div className="p-3">
                <div className="mb-5 col-md-3 mx-auto mt-4 pt-5 text-start login-bg">
                <div class="mt-4 mb-4"><b class="login_heading">Admin Login</b></div>
                    <div class="mb-4">
                    <p class="text-start">User Name</p>
                    <input type="text" className="form-control"
                        placeholder="Enter your user name"
                       
                    />
                    </div>
                    <div class="mb-2">
                    <p class="text-start">Password</p>
                    <input type="password" className="form-control"
                        placeholder="Enter your password"
                        
                    />
                    </div>
                    
                   
                    <div class="">
                            
                              <p className="forgot_password text-white">
                     Forgot Password?
                 </p>
                        </div>
                        <div class="text-center">
                        <a href="https://dealerscar.safe-buy.com.au/admin" class="btn btn-primary btn-lg active" role="button">Reload</a>
                    </div> 
                </div>
                </div>
            </div>
        </div>
    );
}
export default Login;
