import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import FilterDropdown from '../../common/components/FilterDropdown';
import CarCollectionGallary from './CarCollectionGallary';
import Layout from "../../common/userLayout/Layout";
import { DealersGalleryContext } from "../../common/context/DealersGalleryContext/DealersGalleryContext";
import DealersDetails from '../../uitls/routers/dealersDetails';
import '../../assets/css/dealers.css'

const CarCollections = () => {
    const { filterByCars, updateFilterByCar,
        filterByTypes, updateFilterByType,
        filterByDealers, updateFilterByDealers,
        carSort, updateCarsort, allCollections,
        carModel, carFuelType, carOdomerter, carPrice,
        dealerName, filterValue, updateFilterValue } = useContext(DealersGalleryContext)
    const fuleTypes = ["Petrol", "Diesel", "Electric"]
    const { getDatas } = DealersDetails();

    useLayoutEffect(() => {
        getDatas();
    }, [])

    return (
        <div class='text-center'>
            <div class="admin-ui">
                <Layout>
                    <div class="container">
                        <div class="album prd-listing-page">
                            <div class="row  mt-6">
                                <div class="col-md-12 mt-4">
                                    <p class="bread-crum mb-0 mt-2 text-start fs-6 primary_font_color">
                                        Safe buy inspected cars
                                    </p>
                                </div>
                                <div class="d-sm-block">
                                    <div class="row">
                                        <div class="col-12 text-start mt-4">
                                            <b> Fillter by</b>
                                        </div>
                                        <div class="col-10 col-sm-8 text-start mt-4 d-flex">
                                            <div class="col-md-2 col-4 me-2">
                                                <FilterDropdown options={Array.from(new Set(carModel))} dropdownType='carName' value={filterByCars} onChange={updateFilterByCar} defaultVal='Cars' />
                                            </div>
                                            <div class="col-md-2 col-4 me-2">
                                                <FilterDropdown options={Array.from(new Set(fuleTypes))} dropdownType='type' value={filterByTypes} onChange={updateFilterByType} defaultVal='Types' />
                                            </div>
                                            <div class="col-md-2 col-4 me-2">
                                                <FilterDropdown options={Array.from(new Set(dealerName))} dropdownType='dealer' value={filterByDealers} onChange={updateFilterByDealers} defaultVal='Dealers' />
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-2 col-sm-3 col text-start mt-4 d-flex">
                                            <div class="col-12">
                                                <FilterDropdown options={Array.from(new Set(carPrice))} dropdownType='sort' value={carSort} onChange={updateCarsort} defaultVal='Sort' />
                                            </div>
                                        </div>
                                        {filterValue ? (
                                            <div class="col-12 text-start mt-4 filter-selection" >
                                            <p class="filter-outer-result mt-3">
                                                <em>
                                                    <a onClick={()=>updateFilterValue(undefined)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                        </svg>
                                                    </a>
                                                </em>
                                                <span style={{backgroundColor:'orange'}}>{filterValue}</span>
                                            </p>
                                        </div>
                                        ) : ''}
                                    </div>
                                </div>
                                <CarCollectionGallary />
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        </div>
    )
}

export default CarCollections