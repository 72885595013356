import React, { useContext } from 'react'
import ImageCarousel from '../../common/components/ImageCarousel'
import CollectionDetailContent from '../../common/content/CollectionDetailContent'
import CollectionDetailDiscreption from '../../common/content/CollectionDetailDiscreption'
import { CarInfoContext } from '../../common/context/CarInfoContext/CarInfoContext'
import UsersDetailForm from '../../common/form/UsersDetailForm'
import Layout from "../../common/userLayout/Layout";
import "../../assets/css/dealer.css";

const CarInfo = () => {
    const { selectedCarDetail } = useContext(CarInfoContext)
    return (
        <div className="text-center">
            <div className="admin-ui">
                <Layout>
                    <div className="container">
                        <div className="row ">
                            <div className="text-start my-4">
                                <a href="#" className="primary_font_color">Safe buy inspected cars</a> / {selectedCarDetail.carDetails.carModel}
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <ImageCarousel images={selectedCarDetail.carImage} />
                                    <CollectionDetailDiscreption discription={selectedCarDetail.carDescription} />
                                </div>
                                <div className="col-md-4 text-start">
                                    <CollectionDetailContent content={selectedCarDetail} />
                                    <UsersDetailForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        </div>
    )
}

export default CarInfo