import React,{useState,createContext, useEffect} from 'react';


export const LoginContext = createContext({})

export const LoginContextProvider = ({children})=>{
    const [resetMsg, setResetMsg] = useState("");
    const [resetPwd, setResetPwd] = useState("");
    const [confirmPwd, setConfirmPwd] = useState("");
    const [isSupported, setIsSupported] = useState(false);
        
    // useEffect(() => {
    //     const frame = document.createElement("iframe");
    //     frame.id = "3pc";
    //     frame.src = "http://localhost:3000/admin"; //Add your hosted domain url here
    //     frame.style.display = "none";
    //     frame.style.position = "fixed";
    //     document.body.appendChild(frame);

    //     window.addEventListener(
    //         "message",
    //         function listen(event) {
    //             if (event.data === "3pcSupported" || event.data === "3pcUnsupported") {
    //                 setIsSupported(event.data === "3pcSupported");
    //                 document.body.removeChild(frame);
    //                 window.removeEventListener("message", listen);
    //             }
    //         },
    //         false
    //     );
    // }, []);
   
    // return isSupported;

    let cookies = navigator.cookieEnabled;


    const updateResetMsg =(msg)=>{
      setResetMsg(msg)
    }
    const updateResetPwd =(pwd)=>{
      setResetPwd(pwd)
    }
    const updateConfirmPwd =(pwd)=>{
      setConfirmPwd(pwd)
    }
   return (
       <LoginContext.Provider
        value={{resetMsg,
            resetPwd,
            confirmPwd,
            updateResetMsg,
            updateResetPwd,
            updateConfirmPwd
        }}
       >
           {children}

       </LoginContext.Provider>
   )

}




    
