import React from "react";
import Layout from "../../common/userLayout/Layout";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import '../../assets/css/Login.css'
import "../../assets/css/dealer.css"
import { Link } from "react-router-dom";

const SignUpSuccess = (props) => {
  return (
    <div className="text-center">
      <div className="admin-ui">
        <Layout>
          <div className="pwd-container">
            <h1 style={{ color: "orange" }}>Successfully Signed up.Now <Link to="/admin"><span className="sign-up-link">Click here</span></Link> to Login. </h1>
          </div>
        </Layout>
      </div>
    </div>
  );
};
export default SignUpSuccess;