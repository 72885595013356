import React from 'react'
import { GlobalVariable } from '../baseUrl/baseUrl'
import PdfPreview from '../pdf/PdfPreview'

const CollectionDetailContent = ({content}) => {
  return (
    <>
      <h1 class="shape arrow-chevron">{content.dealerName} </h1>
      <h5>
        <b>{content.carDetails.carModel}</b>
      </h5>
      <p><b>${content.carAskingPrice}</b></p>
      <p class="mb-1">Fuel Type: <span class="primary_font_color"> <b>{content.carDetails.carFuelType}</b></span></p>
      <p>Odometer: {content.carDetails.carOdoMeter}</p>
      <div className='pdf-outer'>
      <a href={`${GlobalVariable.BACKEND_API_URL}/${content.carReport.ReportPath}/${content.carReport.ReportName}`} download='inspection_pdf' target='_blank'>
<PdfPreview pdf={`${GlobalVariable.BACKEND_API_URL}/${content.carReport.ReportPath}/${content.carReport.ReportName}`}/>
</a>
      </div>
      <hr class="mt-3 d-block" />
    </>
  )
}

export default CollectionDetailContent