import React from 'react'
// import { Carousel } from 'react-carousel-minimal'
import "../../assets/css/dealer.css"

import Carousel from 'react-bootstrap/Carousel';
import { GlobalVariable } from '../baseUrl/baseUrl';

const ImageCarousel = ({ images }) => {

  return (
    <>
      <Carousel
        autoPlay={true}
        interval={1000}
        controls={true}
        indicators={true}
        indicatorLabels={true}
        className="carousel mb-3"
      >
        {images.map((item, i) => {
          return (
            <Carousel.Item key={i} className='carousel-item'>
              <img
                className="d-block w-100 image"
                src={`${GlobalVariable.BACKEND_API_URL}/${item.ImgPath}/${item.ImgName}`}
                alt="slider image"
              />
            </Carousel.Item>
          )
        })}
      </Carousel>
    </>
  )
}

export default ImageCarousel