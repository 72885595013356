import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LoginContext } from "../../common/context/loginContext/loginContext";
import Button from '../../common/button/Button';
import Input from "../../common/textInput/Input";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Layout from "../../common/userLayout/Layout";
import { GlobalVariable } from "../../common/baseUrl/baseUrl";
import "../../assets/css/dealer.css"


const Resetpwd = ({ ...props }) => {
  let navigate = useNavigate()
  const {
    resetPwd,
    confirmPwd,
    updateResetPwd,
    updateConfirmPwd,
    resetMsg, updateResetMsg
  } = useContext(LoginContext);

  const id = localStorage.getItem("userId")
  const Reset = () => {
    if (resetPwd === confirmPwd) {
      updateResetMsg("")
      axios.post(`${GlobalVariable.BACKEND_API_URL}/reset-pwd`, {
        pwd: confirmPwd,
        userId: id
      }).then((res) => {

        navigate("/reset-pwd-success")
      })

    } else {

      updateResetMsg("password doesn't match")
    }
  }
  return (
    <div className="text-center">
      <div className="admin-ui">
        <Layout>
          <div>
            <div className="text-center text-white">
              <div className="full_bg">
                <section>
                  <div className="p-3">
                    <form>
                      <div className="mb-5 col-md-3 offset-md-8 mt-4 pt-5 text-start">
                        <div className="mt-4 mb-4">
                          <b className="login_heading">Reset Password</b>
                        </div>
                        <div className="mb-4">
                          <p className="text-start">New password</p>
                          <Input
                            placeholder="Enter a New password"
                            name="userNewPassword"
                            type="password"
                            className="form-control mb-4 p-2"
                            value={resetPwd}
                            onChange={(e) => {
                              updateResetPwd(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-2">
                          <p className="text-start">Confirm password</p>
                          <Input
                            placeholder="Confirm password"
                            name="userConfirmPassword"
                            type="password"
                            className="form-control mb-4 p-2"
                            value={confirmPwd}
                            onChange={(e) => {
                              updateConfirmPwd(e.target.value);
                            }}
                          />
                        </div>
                        <span className="form-msg">{resetMsg}</span>
                        <div className="text-end">
                          <Button
                            label="Reset Password"
                            handleClick={Reset}
                            className="btn btn-dark mt-4 main_btn border-0"

                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  );
};
export default Resetpwd
