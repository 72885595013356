import React, { useContext } from 'react';
import axios from 'axios'
import { DealersGalleryContext } from '../../common/context/DealersGalleryContext/DealersGalleryContext';
import { CarInfoContext } from '../../common/context/CarInfoContext/CarInfoContext';
import {GlobalVariable} from '../../common/baseUrl/baseUrl'

const DealersDetails = () => {
  const {updateSetCarModel,updateSetCarFuelType,
    updateSetCarOdomerter, updateSetCarPrice, updateDefaultCollections, 
    updateDealerName} = useContext(DealersGalleryContext)
    
  const getDatas = async () => {
     await axios.get(`${GlobalVariable.BACKEND_API_URL}/get`)
     .then(res => {
       const data = res.data
       const carModels = [], carFuelTypes = [], carOdoMeters = [], carPrices = [], carDetails = [], dealerNames=[] ;

       data.forEach((item, index) => {
         const { carModel, carFuelType, carOdoMeter } = item.carDetails;
         carModels.push(carModel);
         carFuelTypes.push(carFuelType);
         carOdoMeters.push(carOdoMeter);
         carPrices.push(item.carAskingPrice);
         carDetails.push(item.carDetails);
         dealerNames.push(item.dealerName)
       })
       updateSetCarModel(carModels)
       updateSetCarFuelType(carFuelTypes)
       updateSetCarOdomerter(carOdoMeters)
       updateSetCarPrice(carPrices)
       updateDealerName(dealerNames)
       updateDefaultCollections(res.data)
      //  console.log("data", res.data);
     })
  }

  const enquiryDetails = async (params) => {
    await axios.post(`${GlobalVariable.BACKEND_API_URL}/enquirydetails`,params)
    .then(res=>{
      // console.log("Enquiry datas",res.data);
    })
  }

  return {getDatas,enquiryDetails}
}

export default DealersDetails