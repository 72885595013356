import axios from 'axios';
import { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/safe-buy-logo.png'
import Button from '../../common/button/Button'
import { CarDetailsContext } from '../../common/context/carDetailsContext/CarDetailsContext';
import '../../assets/css/Login.css';
import '../../assets/css/dealer.css'
import {GlobalVariable} from '../../common/baseUrl/baseUrl'


function Login() {
    const navigate = useNavigate();

    const [loginData, setloginData] = useState({
        username: "",
        password: "",
        email: "",
        phonenumber: "",
        inspectorId: "",
    })
    const [loginErrMsg, setLoginErrMsg] = useState("")
    const [isSignUp, setIsSignUp] = useState(false)
    useEffect(()=>{
        localStorage.clear()
    },[])
    const loginBtn = (e) => {
        e.preventDefault();
            axios.post(`${GlobalVariable.BACKEND_API_URL}/login`,loginData)
            .then((res) => {
                localStorage.setItem("inspectorId", res.data._id)
                localStorage.setItem("token", res.data.token)
                navigate("/details")
            })
            .catch((err) => {
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.errorMessage
                ) {
                    setLoginErrMsg(err.response.data.errorMessage);
                }
            })
    }

    const signUp = (e) => {
        e.preventDefault();
        axios.post(`${GlobalVariable.BACKEND_API_URL}/sign-up`, loginData)
            .then((res) => {
                navigate("/sign-up")
            })
            .catch((err) => {
                if (
                    err.response &&
                    err.response.data &&
                    err.response.data.errorMessage
                ) {
                    setLoginErrMsg(err.response.data.errorMessage);
                }
            })
    }
    return (
        <div className='text-center text-white'>
            <div className='admin-ui full_bg'>
                <div class="top_header">
                    <div class="container p-2">
                        <div class="row">
                            <div class="col-md-2">
                                <a class="navbar-brand" href="#"> <img src={logo} style={{ height:'52px', width:'160px' }} /></a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='logo'>
               <img src={logo}/>
           </div> */}
                <div className="p-3">
                <div className="mb-5 col-md-3 mx-auto mt-4 pt-5 text-start login-bg">
                <div class="mt-4 mb-4"><b class="login_heading">Admin Login</b></div>
                    <div class="mb-4">
                    <p class="text-start">User Name</p>
                    <input type="text" className="form-control"
                        placeholder="Enter your user name"
                        onChange={(e) => { 
                            setloginData({ ...loginData, username: e.target.value })
                            localStorage.setItem('userName', e.target.value)
                        }}
                    />
                    </div>
                    <div class="mb-4">
                    <p class="text-start">Password</p>
                    <input type="password" className="form-control"
                        placeholder="Enter your password"
                        onChange={(e) => { setloginData({ ...loginData, password: e.target.value }) }}
                    />
                    </div>
                    {isSignUp ? 
                        <>
                            <div class="mb-4">
                            <p class="text-start">Email</p>
                            <input type="email" className="form-control"
                                placeholder="Enter your password"
                                onChange={(e) => { setloginData({ ...loginData, email: e.target.value }) }}
                            />
                            </div>    
                            <div class="mb-4">
                            <p class="text-start">Phone Number</p>
                            <input type="number" className="form-control"
                                placeholder="Enter your password"
                                onChange={(e) => { setloginData({ ...loginData, phonenumber: e.target.value }) }}
                            />
                            </div>
                            <div class="mb-2">
                            <p class="text-start">Inspector Id</p>
                            <input type="number" className="form-control"
                                placeholder="Enter your password"
                                onChange={(e) => { setloginData({ ...loginData, inspectorId: e.target.value }) }}
                            />
                            </div>        
                        </> : ''}
                    
                    <span className="msg">{loginErrMsg}</span>
                    <div class="mb-5">
                        <p> <u> <Link to="/forgotpwd" className="forgot_password">
                            Forgot Password?
                        </Link></u></p>
                    </div>
                    <div class="text-end d-flex justify-content-end">
                    {/* <Button
                        label="Sign up"
                        className='text-end btn primary_color text-white m-1'
                        handleClick={(e)=>loginData.email != "" ? signUp(e) : setIsSignUp(true)}
                        // handleClick={()=>loginData.email != "" ? console.log("api") : setIsSignUp(true)}
                    /> */}
                    <Link to="/details" className='login-btn'
                    >
                        <Button
                            label="Login"
                            className='btn primary_color text-white m-1'
                            handleClick={loginBtn}
                        />
                    </Link>
                    </div>
                    {/* <Link to="/register"
                    >
                        Register
                    </Link> */}
                </div>
                </div>
            </div>
        </div>
    );
}
export default Login;
