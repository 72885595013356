import React, { useState } from "react";
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import Button from '../../common/button/Button';
import Input from "../../common/textInput/Input";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Layout from "../../common/userLayout/Layout";
import { GlobalVariable } from "../../common/baseUrl/baseUrl";
import '../../assets/css/dealers.css'
import sendEmail from "../../common/email/email";

export const ForgotPwd = ()=>{
  let navigate = useNavigate()
    const [email,setEmail] = useState("")
    const Message = `your reset link is ${GlobalVariable.BACKEND_API_URL}/password-reset/123/345345`
    const ForgotPwd = (e)=>{
        e.preventDefault()
          axios.post(`${GlobalVariable.BACKEND_API_URL}/forget-pwd`,{email:email})
          .then((res)=> {
              // console.log("email",res.data)
              localStorage.setItem("userId",res.data.userId)
              sendEmail({user_name:'test user', user_email : email, message:Message})
              navigate("/forgot-pwd-success")
            })
          .catch((err)=> console.log(err))
  
         }
    return (
      <Layout>
        {/* <input type="email" placeholder="Enter your registered email" 
        onChange={(e)=>{setEmail(e.target.value)}}/>
        <button onClick={ForgotPwd}>Get reset Link</button> */}

   <div>
        <div className="text-center text-white">
          <div className="full_bg">
            <section>
              <div className="p-3">
                <form>
                  <div className="mb-5 col-md-3 offset-md-8 mt-4 pt-5 text-start">
                    <div className="mt-4 mb-4">
                      <b className="heading">Forgot password</b>
                    </div>
                    <div className="mb-4">
                      <p className="text-start">Enter Registred Email</p>
                      <Input
                        placeholder="Enter Email"
                        name="userName"
                        type="text"
                        value={email}
                        className="form-control mb-4 p-2"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    {/* <span className="form-msg">{forgotPwdMsg}</span> */}
                    <div className="text-end">
                      <Button
                        label="Send Email"
                        handleClick={ForgotPwd}
                        className="btn btn-dark mt-4 main_btn border-0"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
        
      </Layout>
    )
}
