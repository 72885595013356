import React from "react";
import Layout from "../../common/userLayout/Layout";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import '../../assets/css/Login.css'
import "../../assets/css/dealer.css"

const ForgotpwdSuccess = (props) => {
  return (
    <div className="text-center">
      <div className="admin-ui">
        <Layout>
          <div className="pwd-container">
            <h1 style={{ color: "orange" }}>Reset link is sent to your Email </h1>
          </div>
        </Layout>
      </div>
    </div>
  );
};
export default ForgotpwdSuccess;