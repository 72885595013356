import React, { useContext, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { CarDetailsContext } from '../../context/carDetailsContext/CarDetailsContext';
import "../../../assets/css/Pdf.css";

export default function Pdf() {
  const {pdfFile} = useContext(CarDetailsContext)

  pdfjs.GlobalWorkerOptions.workerSrc =
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  return (
    <>
      <Document
        file={pdfFile}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </>
  );
}