import React, { useState, useContext } from "react";
import { CarDetailsContext } from "../../context/carDetailsContext/CarDetailsContext";
import Carousel from 'react-bootstrap/Carousel';
import "../../../../node_modules/bootstrap/dist/css/bootstrap.css"

function HomeCarousel() {

  const { imgArr } = useContext(CarDetailsContext)
  const arrayFile = imgArr

  const handleFileChosen = async (file) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsDataURL(file);
    });
  }

  const [resultFiles, setResultFiles] = useState([])
  const readAllFiles = async (AllFiles) => {
    const results = await Promise.all(AllFiles.map(async (file) => {
      const fileContents = await handleFileChosen(file);
      return fileContents;
    }));
    // console.log("results",results);
    setResultFiles(results)
    return results;
  }
  readAllFiles(arrayFile)
  //console.log("readFiles",readFiles)
  return (
    <Carousel
      autoPlay={true}
      interval={1000}
     controls={true}
     indicators={false}
     indicatorLabels={true}
      className="carousel"
    >
      {resultFiles.map((slide, i) => {
        return (
          <Carousel.Item key={i} calssName='carousel-item'>
            <img
              className="d-block w-100 image"
              src={slide}
              alt="slider image"
            />
          </Carousel.Item>
        )
      })}

    </Carousel>
  );
}
export default HomeCarousel;