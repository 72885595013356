import React, { useContext} from 'react'
import DealersDetails from '../../uitls/routers/dealersDetails';
import { CarInfoContext } from '../context/CarInfoContext/CarInfoContext';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "../../assets/css/dealer.css"
import sendEmail from '../email/email';

const UsersDetailForm = () => {

  const { userFirstName, updateFirstName,
    userLastName, updateLastName,
    mobileNumber, updatemobileNumber,
    userEmail, updateEamil, selectedCarDetail,
    enquireValidation, errMsgFirstName, errMsgLastName,
    errMsgEmail, errMsgMobileNo, updateErrMsgFirstName,
    updateErrMsgLastName, updateErrMsgEmail,
    updateErrMsgMobile, publish, confirmMsg, updateConfarmationMsg, 
    publishErr } = useContext(CarInfoContext);

  const AdminMessage = `A Enquiry was done by ${userEmail} ${mobileNumber} for car ${selectedCarDetail.carDetails.carModel} by ${selectedCarDetail.dealerEmail} `

  const dealerMessage = `your car ${selectedCarDetail.carDetails.carModel} with price $${selectedCarDetail.carAskingPrice} is being enquired by ${userFirstName + " " + userLastName} ${mobileNumber}
  and his email id is ${userEmail}. Please get in touch with him.`

  const ClientMessage = `Your enquiry for ${selectedCarDetail.carDetails.carModel} is Noticed, the sales team will get in touch with you.`

  const { enquiryDetails } = DealersDetails();


  const request = {
    clientEmail: userEmail,
    clientMobile: mobileNumber,
    clientFullName: userFirstName + " " + userLastName,
    dealerEmail: selectedCarDetail.dealerEmail,//allCollections.dealerEmail,
    dealerId: selectedCarDetail.dealerId,
    carId: selectedCarDetail.carId,
    status: 'success',
    enquiryDateTime: new Date().toLocaleString()
  }
  return (
    <div className="mb-5 d-block">
      <p>Contact seller to enquire on this car</p>
      <input
        type='text'
        name="user_name"
        className={`form-control mb-3 p-2 ${errMsgFirstName ? 'invalid' : ''} `}
        value={userFirstName} placeholder="First Name"
        onChange={(e) => {
          updateFirstName(e.target.value)
          updateErrMsgFirstName(false)
        }} />
      <input
        type='text'
        className={`form-control mb-3 p-2 ${errMsgLastName ? 'invalid' : ''} `}
        value={userLastName} placeholder="Last Name"
        onChange={(e) => {
          updateLastName(e.target.value)
          updateErrMsgLastName(false)
        }} />
      <input
        type='number'
        className={`form-control mb-3 p-2 ${errMsgMobileNo ? 'invalid' : ''} `}
        value={mobileNumber} placeholder="Mobile Number"
        onChange={(e) => {
          updatemobileNumber(e.target.value)
          updateErrMsgMobile(false)
        }} />
      <input
        type='text'
        pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
        name="user_email"
        className={`form-control mb-3 p-2 ${errMsgEmail ? 'invalid' : ''} `}
        value={userEmail} placeholder="Email"
        onChange={(e) => {
          updateEamil(e.target.value)
          updateErrMsgEmail(false)
        }} required/>
      {confirmMsg ? <span className='publish-text'>*RECEIVED YOUR ENQUIRY. WE WILL GET BACK TO YOU</span> : ""}
      {publishErr ? <span className='publish-text-err'>*PLEASE CHECK YOUR DETAILS</span> : ""}
      <p>
        <button
          onClick={(e) => {
            e.preventDefault()
            enquireValidation()
            publish(request,()=>{
            sendEmail({ user_name: userFirstName + " " + userLastName, user_email: userEmail, message: ClientMessage, admin:'ADMIN' },
              sendEmail({ user_name: selectedCarDetail.dealerName, user_email: selectedCarDetail.dealerEmail, message: dealerMessage, admin:'ADMIN' },
                sendEmail({ user_name: 'ADMIN', user_email: userEmail, message: AdminMessage })))})
          }}
          className="btn btn-dark mt-4 main_btn border-0 ">Send</button>
      </p>
    </div>
  )
}

export default UsersDetailForm