import React from "react";
import {compineComponents} from '../../../uitls/combainProvider/CompineComponents';
import { DealersGalleryProvider } from "../DealersGalleryContext/DealersGalleryContext";
import { CarInfoContextProvider } from "../CarInfoContext/CarInfoContext";
import { CarDetailsContextProvider } from "../carDetailsContext/CarDetailsContext";
import { LoginContextProvider } from "../loginContext/loginContext";

const providers = [
  DealersGalleryProvider,
  CarInfoContextProvider,
  CarDetailsContextProvider,
  LoginContextProvider
]

export const AppContextProvider = compineComponents(...providers);