import React, { useState } from 'react'
import '../../../assets/css/Modal.css';
import Button from '../../button/Button';
import { useContext } from 'react';
import { CarDetailsContext } from '../../context/carDetailsContext/CarDetailsContext';
import HomeCarousel from '../carousel/Carousel';
import Pdf from '../Pdf/Pdf';



function Modal({ show }) {

    const { imgArr, carOdoMeter, fuelType, saveDetails,
        dealerName, invalidPublish, updateInvalidPublish, 
        carPrice, carModel, isPublished,carDescription } = useContext(CarDetailsContext)

    const [result, setResult] = useState()
    const previewFile = () => {
        // console.log("true");
        var preview = document.querySelector("img");
        var file = imgArr[0];
        // console.log(file);
        var reader = new FileReader();

        reader.addEventListener(
            "load",
            function () {
                const res = reader.result;
                setResult(res)
            },
            false
        );
        if (file) reader.readAsDataURL(file);
        return result;
    }
    previewFile();

    return (
        <div className="modal-container">
            <div className='modal-content box-shadow'>
                <div className="modal-header">
                    <h5 className="modal-title text-dark" id="exampleModalLabel">{dealerName}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                        onClick={() => {
                            updateInvalidPublish(false)
                            show()
                        }}></button>
                </div>
                <div className='modal-body'>
                    <form>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-md-8">
                                    <HomeCarousel />
                                </div>
                                <div className="col-md-4 text-start  text-dark">
                                    <h5>
                                        <b>{carModel}</b>
                                    </h5>
                                    <p>${carPrice}</p>
                                    <p>Fuel Type:  <span className="primary_font_color">{fuelType}</span></p>
                                    <p>Odometer: {carOdoMeter} Kms</p>
                                    <p>{carDescription}</p>
                                    <div className="pdf-container">
                                        <Pdf />
                                    </div>
                                    <div>
                                    </div>
                                    <Button
                                        label="Publish"
                                        className="btn btn-dark mt-4 main_btn border-0"
                                        handleClick={()=>{saveDetails(show)}}
                                    />
                                    {invalidPublish ? <p style={{ color: 'red', marginTop:'2px' }}>*please fill required fields</p> : ''}
                                    {isPublished ? <p style={{ color: 'Green', marginTop:'2px' }}>*Successfully updated</p> : ''}
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Modal;