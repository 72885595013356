import React, { useContext } from 'react'
import { DealersGalleryContext } from '../../common/context/DealersGalleryContext/DealersGalleryContext';
import DefaultGallery from './DefaultGallery';
import FilteredGallery from './FilteredGallery';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";

const CarCollectionGallary = ({ data }) => {
  const { filterByCars,
    filterByTypes, filterByDealers,
    carSort, currentPage, updateCurrentPage,
    postPerPage, filteredGallary, 
    allCollections, filterValue } = useContext(DealersGalleryContext)

  const Gallery = [...allCollections]
  const Filter = [...filteredGallary]
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const DefaultCurrentPost = Gallery.slice(indexOfFirstPost, indexOfLastPost)
  const filteredCurrentPost = Filter.slice(indexOfFirstPost, indexOfLastPost)
  const paginate = (number) => { updateCurrentPage(number) }
  return (
    <>
      <div class="row page1">
        {(filterValue ) === undefined ?
          <DefaultGallery
            data={DefaultCurrentPost}
            totalPosts={Gallery.length}
            postPerPage={postPerPage}
            paginate={paginate}
          />
          :
          <FilteredGallery
            data={filteredCurrentPost}
            totalPosts={Gallery.length}
            postPerPage={postPerPage}
            paginate={paginate}
          />}
      </div>
    </>
  )
}

export default CarCollectionGallary