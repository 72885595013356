import React, { useContext, useEffect, useState } from 'react'
import { DealersGalleryContext } from '../context/DealersGalleryContext/DealersGalleryContext';

const FilterDropdown = ({options, value, onChange, dropdownType, defaultVal}) => {
 const {FilterBy, updateFilterValue} = useContext(DealersGalleryContext)
 
  return (
    <select class="form-select" value={value} aria-label="Default select example" onChange={(e)=>{
      onChange(e.target.value)
      FilterBy(e.target.value, dropdownType)
      updateFilterValue(e.target.value)
      }}>
        <option>{defaultVal}</option>
        {options !== undefined ? options.map((item)=>(
          <option>{item}</option>
        )):''}
    </select>
  )
}

export default FilterDropdown