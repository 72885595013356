import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

const PdfPreview = ({ pdf }) => {

  pdfjs.GlobalWorkerOptions.workerSrc =
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  return (
    <div>
      <Document file={pdf}>
        <Page pageNumber={1} />
      </Document>
    </div>
  )
}

export default PdfPreview