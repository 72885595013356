import '../../App.css'
import React from "react";

const Button = ({ label,className,handleClick }) => {
  return (
    <div>
      <button
        type="button"
        className={className}
         onClick={handleClick}
      >
        <span>{label}</span>
      </button>
    </div>
  );
};
export default Button;